import { ADVANCED_SEARCH_MODE } from '@/constants/submissions';
import { ref } from 'vue';

const advanceSearchFilter = ref([]);
const simpleSearchFilters = ref({
    searchFields: [],
    searchString: ''
});
const searchMode = ref(ADVANCED_SEARCH_MODE);

export default () => {
    return {
        advanceSearchFilter,
        simpleSearchFilters,
        searchMode
    };
};
